import React from "react";
const Input = ({ name, label, error, ...rest }) => {
  return (
    <div className="control">
      <label
        className="label"
        htmlFor={name}
      >
        {label}
      </label>
      <input
        // block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
        className="input"
        {...rest}
        id={name}
        name={name}
      />
      {error && <p className="error" style={{color:"red"}}>{error}</p>}
    </div>
  );
};

export default Input;
