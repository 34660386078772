import React from "react";
import { faLocationDot, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../style/main.css";

// import he from './../../node_modules/date-fns/esm/locale/he/index.d';
const DoctorCard = ({id,name,image,speciality,availableTime,availableBranchs,onBook,onViewProfile}) => {
    availableBranchs = "Bole Rwanda, Bole Bulbula";
    availableTime = "Available Mon-Thu 8:00 AM - 5:00 PM";
  
  return (
    <div className="container has-text-centered doctorCard" style={{cursor:"pointer"}} onClick={() =>{onBook(id)}}>
      <div className="card" style={{height:"26em"}}>
        <div className="card-image">
        <p>Click Here To Book Appointment</p>
          <figure className="image is-4by3">
            <img
              id= {`doctorImage${id}`}
              src={image}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png";
              }}
              style={{ objectFit: "contain" }}
              alt="Placeholder image"
            />
          </figure>
        </div>
        <div className="card-content">
          <div className="content">
            <div className="media-content">
              <h5 style={{ textAlign: "left",textOverflow: "initial" }}>{name}</h5>
              <h6
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  color: "#C2C2C2",
                }}
              >
                {speciality}
              </h6>
              {/* <h6
                style={{
                  textAlign: "left",
                  fontSize: "10px",
                  color: "#000000",
                }}
              >
                <FontAwesomeIcon icon={faLocationDot} />
                &nbsp; &nbsp; {availableBranchs}
                </h6>
              <h6
                style={{
                  textAlign: "left",
                  fontSize: "10px",
                  color: "#000000",
                }}
              >
                <FontAwesomeIcon icon={faClock} />
                &nbsp; &nbsp; {availableTime}
              </h6> */}
              <div className="columns is-centered">
                {/* <div className="column">
                  <button className="button is-info is-outlined" onClick={() => {onViewProfile(id)}}>
                    View Profile
                  </button>
                </div> */}
                {/* <div className="column">
                  <button className="button is-success is-focused" onClick={() =>{onBook(id)}}>
                    Book Now
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorCard;
