import React from "react";
const SelectField = ({ name, label, options, error, ...rest }) => {
  return (
    <React.Fragment>
      <div className="control">
        <label className="label" htmlFor={name}>
          {label}
        </label>
        <div className="select is-primary">
          <select name={name} id={name} {...rest}>
            <option value="" />
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        {error && <p className="error" style={{color:"red"}}>{error}</p>}
      </div>
    </React.Fragment>
  );
};

export default SelectField;
