import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocation } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer
        className="footer"
        style={{ backgroundColor: "#15558D", color: "white" }}
      >
        <div className="columns">
            <div className="column is-half">
          <div className="content has-text-centered">
              <h1
                style={{ textAlign: "left", fontSize: "20px", color: "white" }}
              >
                Washington Medical Center
              </h1>
              <p style={{ textAlign: "left",  }}>
                {" "}
                WMC constitutes a specialized secondary care facility situated in Addis Ababa. The facility is under the proficient management of adept Ethiopian and American physicians. Its distinguished reputation stems from its exceptional proficiency in internal medicine, maternal and child health, advanced ambulance services, diagnostic imaging, and laboratory diagnostics. Moreover, it boasts an array of consultant physicians, each specializing in various medical disciplines, including cardiology, neurology, hematology, obstetrics and gynecology, ENT, general surgery, psychiatry, endocrinology, gastroenterology, rheumatology, urology, and dermatology.

Over a relatively short period, WMC has experienced substantial growth, establishing itself as one of the foremost and most esteemed medical centers in Addis Ababa. This expansion has enabled WMC to provide comprehensive medical care to a substantial patient base, serving in excess of 750,000 individuals.
              </p>
              <p
                style={{ textAlign: "left", fontSize: "20px", color: "white" }}
              >
                <a href="https://www.facebook.com">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    style={{ color: "white" }}
                  />
                </a>
                &nbsp; &nbsp; &nbsp;
                <a href="https://www.twitter.com">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    style={{ color: "white" }}
                  />
                </a>
                &nbsp; &nbsp; &nbsp;
                <a href="https://www.linkedin.com">
                  {" "}
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    style={{ color: "white" }}
                  />
                </a>
              </p>
              
                <h6
                  style={{
                    textAlign: "left",
                    fontSize: "15px",
                    color: "white",
                  }}
                >
                  {" "}
                  <FontAwesomeIcon icon={faMapLocation} /> Bole Bulbula
                </h6>
                <h6
                  style={{
                    textAlign: "left",
                    fontSize: "15px",
                    color: "white",
                  }}
                >
                  {" "}
                  <FontAwesomeIcon icon={faMapLocation} /> Bole Rwanda
                </h6>
              
              <p style={{ color: "white", textAlign: "left", bottom: "2px" }}>
                © 2022 Bemmy Technology Group. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
