import React from 'react';
import bulmaCalendar from "bulma-calendar/dist/js/bulma-calendar.min.js";

const Calandar = ({onChange,doctor}) => {

    let disabledWeekDays = [];
    if(doctor){
      if (doctor.availableStartTimeMonday == "00:00:00" && doctor.availableEndTimeMonday == "00:00:00" && doctor.availableStartTimeMonday1 == "00:00:00" && doctor.availableEndTimeMonday1 == "00:00:00"){
        disabledWeekDays.push(1)
      } 
      if(doctor.availableStartTimeTuesday == "00:00:00" && doctor.availableEndTimeTuesday == "00:00:00" && doctor.availableStartTimeTuesday1 == "00:00:00" && doctor.availableEndTimeTuesday1 == "00:00:00"){
        disabledWeekDays.push(2)
      }
      if(doctor.availableStartTimeWednesday == "00:00:00" && doctor.availableEndTimeWednesday == "00:00:00" && doctor.availableStartTimeWednesday1 == "00:00:00" && doctor.availableEndTimeWednesday1 == "00:00:00"){
        disabledWeekDays.push(3)
      }
      if(doctor.availableStartTimeThursday == "00:00:00" && doctor.availableEndTimeThursday == "00:00:00" && doctor.availableStartTimeThursday1 == "00:00:00" && doctor.availableEndTimeThursday1 == "00:00:00"){
        disabledWeekDays.push(4)
      }
      if(doctor.availableStartTimeFriday == "00:00:00" && doctor.availableEndTimeFriday == "00:00:00" && doctor.availableStartTimeFriday1 == "00:00:00" && doctor.availableEndTimeFriday1 == "00:00:00"){
        disabledWeekDays.push(5)
      }
      if(doctor.availableStartTimeSaturday == "00:00:00" && doctor.availableEndTimeSaturday == "00:00:00" && doctor.availableStartTimeSaturday1 == "00:00:00" && doctor.availableEndTimeSaturday1 == "00:00:00"){
        disabledWeekDays.push(6)
      }
      if(doctor.availableStartTimeSunday == "00:00:00" && doctor.availableEndTimeSunday == "00:00:00" && doctor.availableStartTimeSunday1 == "00:00:00" && doctor.availableEndTimeSunday1 == "00:00:00"){
        disabledWeekDays.push(0)
      }
      

    }



    const  calendars = bulmaCalendar.attach('[type="date"]', {
        dateFormat: "yyyy-MM-dd",
        displayMode: "inline",
        showHeader: true,
        showFooter: true,
        showClearButton: false,
        showTodayButton: true,
        showCloseButton: true,
        closeOnOverlayClick: true,
        closeOnSelect: true,
        closeOnClear: true,
        closeOnToday: true,
        closeOnEsc: true,
        startDate: new Date(),
        minDate: new Date(),
        disabledWeekDays: disabledWeekDays,

        

        
      });
    return ( 
       <div >
        {calendars.map(calendar => {
        
            calendar.on("select", onChange);
        })
        }
       </div> 
     );
}
 
export default Calandar;