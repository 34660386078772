import React, { Component, useEffect } from "react";

import Navbar from "../common/navBar1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faLocationDot,
  faCalendarAlt,
  faClock,
  faMapLocation,
} from "@fortawesome/free-solid-svg-icons";
import "../style/main.css";
import Joi from "joi-browser";
import Form from "../common/form";
import Footer from "../common/footer";
import TimeBox from "../common/timeBox";
import WithRouter, { withRouter } from "../utils/withRouter";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
import AppointmentDay from "./AppointmentDay";
import Calandar from "../common/calandar";
import { pushed } from "fontawesome";

// CommonJS

class BookAppointment extends Form {
  state = {
    opdUnitID: "",
    doctorName: "",
    image: "",
    department: "",
    speciality: "",
    doctor: {},
    data: {
      appointmentDate: "",
      branch: "",
      selected: "",
    },
    errors: {},
  };
  schema = {
    appointmentDate: Joi.string().optional(),
    branch: Joi.string().optional(),
  };
  async componentDidMount() {
    const _id = this.props.params["_id"];
    const resp = await axios.get(
      "https://wmcemrportal.wmcethiopia.com/filter/getAppointmentOpdUnitList.php"
    );
    const doctors = resp.data;
    doctors.forEach((element) => {
      if (element.id == _id) {
        this.setState({
          opdUnitID: _id,
          doctorName: element.doctorName,
          image: `https://appointment.wmcethiopia.com/images/${element.id}.png`,
          speciality: element.specialityTitle,
          department: element.doctorDepartment,
        });
        this.setState({ doctor: element });
      }
    });
  }
  getSelectedDayStarAndEndTime = () => {
    // let date = this.state.data.appointmentDate;
    const date = document.getElementById("appointmentDate").value;
    // get the weekday as a number (0-6)
    let day = new Date(date).getDay();
    // convert the day to a string
    day = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][day];
    const startTime1 = moment(
      this.state.doctor[`availableStartTime${day}`],
      "HH:mm:ss"
    ).format("HH:mm");
    const endTime1 = moment(
      this.state.doctor[`availableEndTime${day}`],
      "HH:mm:ss"
    ).format("HH:mm");
    const startTime2 = moment(
      this.state.doctor[`availableStartTime${day}1`],
      "HH:mm:ss"
    ).format("HH:mm");
    const endTime2 = moment(
      this.state.doctor[`availableEndTime${day}1`],
      "HH:mm:ss"
    ).format("HH:mm");
    return { startTime1, endTime1, startTime2, endTime2 };
    //
  };
  renderTimeBoxes = () => {
    if (this.state.data.appointmentDate != "") {
      var counter = 0;
      var timeboxes = [];
      var column = [];
      let intervals = [];
      // woringhours[startTime, endTime, startTime1, endTime1]

      let workingHours = this.getSelectedDayStarAndEndTime();
      let startHour = parseInt(workingHours.startTime1.split(":")[0]);
      let endHour = parseInt(workingHours.endTime1.split(":")[0]);
      let startHour1 = parseInt(workingHours.startTime2.split(":")[0]);
      let endHour1 = parseInt(workingHours.endTime2.split(":")[0]);
      if (startHour != endHour) {
        if (endHour < startHour) {
          // //console.log(startHour, endHour);
          for (var i = startHour; i < 12; i++) {
            intervals.push(`${i}:00 AM`);
            intervals.push(`${i}:30 AM`);
          }
          intervals.push(`12:00 PM`);
          intervals.push(`12:30 PM`);
          for (var i = 1; i < endHour; i++) {
            intervals.push(`${i}:00 PM`);
            intervals.push(`${i}:30 PM`);
          }
        } else {
          for (var i = startHour; i < endHour; i++) {
			  var timex = i;
			  var ampm = "AM";
			  if(i < 12)
			  {
			  }
			  else if(i == 12)
			  {
				  ampm = "PM";
			  }
			  else
			  {
				  timex = i - 12;
				  ampm = "PM";
			  }
            intervals.push(`${timex}:00 ${ampm}`);
            intervals.push(`${timex}:30 ${ampm}`);
          }
        }
      }
      if (startHour1 != endHour1) {
        if (endHour1 < startHour1) {
          for (var i = startHour1; i < 12; i++) {
            intervals.push(`${i}:00 PM`);
            intervals.push(`${i}:30 PM`);
          }
          intervals.push(`12:00 AM`);
          intervals.push(`12:30 AM`);
          for (var i = 1; i < endHour1; i++) {
            intervals.push(`${i}:00 AM`);
            intervals.push(`${i}:30 AM`);
          }
        } else {
          for (var i = startHour1; i < endHour1; i++) {
            intervals.push(`${i}:00 PM`);
            intervals.push(`${i}:30 PM`);
          }
        }
      }

      intervals.forEach((interval) => {
        timeboxes.push(
          <TimeBox
            time={interval}
            id={interval}
            selected={this.state.data.selected}
            onSelected={this.handleTimeSelect}
          />
        );
        counter += 1;
      });
      column.push(<div className="columns is-multiline">{timeboxes}</div>);

      return (
        <React.Fragment>
          <br />
          <p className="subtitle">Select Time</p>
          <div className="select">
            <select onChange={this.handleTimeSelect}>
              <option value={""}>Select Time</option>
              {intervals.map((interval) => {
                return interval == this.state.data.selected ? (
                  <option value={interval} selected>
                    {interval}
                  </option>
                ) : (
                  <option value={interval}>{interval}</option>
                );
              })}
            </select>
          </div>
        </React.Fragment>
      );
    }
  };
  handleTimeSelect = (e) => {
    const id = e.target.value;
    //console.log(id,"is Here");
    let data = this.state.data;
    // convert given time to 24 hours format from 12:00 AM to 00:00
    let time = moment(id, "hh:mm A").format("HH:mm:ss");
    //console.log(moment(time, "HH:mm:ss").format("hh:mm A"));
    data.selected = time;
    //console.log(id,time);
    this.setState({ data });
  };
  renderAppointment = () => {
    if (
      this.state.data.appointmentDate != "" &&
      this.state.data.selected != ""
    ) {
      // if (1) {
      return (
        <React.Fragment>
          <div className="tile is-parent" style={{}}>
            <article
              className="tile is-child notification"
              style={{ backgroundColor: "white" }}
            ></article>
          </div>
          <div className="control column">
            <a
              className="button is-primary"
              onClick={() => {
                let timerInterval;
                let error = false;
                
                Swal.fire({
                  title: "Personal Information",
                  html: '\
                  <label for="name" style="">Fullname</label>\
                  <input class="input is-primary" id="name" style="margin-bottom:20px" type="text" placeholder="Full Name" required>\
                  <br/>\
                  <label for="name" style="">Gender</label>                  <br/>\
                  <select name="gender" id="gender" class="input is-primary" style="margin-bottom:20px">                   <option>Select Gender</option>                     <option value="M">Male</option>                     <option value="F">Female</option>                     </select>\
                  <label for="dob">Date of birth</label>\
                  <input class="input is-primary" id="dob" style="margin-bottom:20px" type="date" placeholder="Date of Birth"  max="3000-01-01" onfocus="this.max=new Date().toISOString().split(\'T\')[0]" required >\
                  <br/>\
                  <label for="phoneNumber">Phone number</label>\
                  <input class="input is-primary" id="phoneNumber" style="margin-bottom:20px" type="text" placeholder="Phone Number" required>\
                  <br/>\
                  <label for="email">Email</label>\
                  <input class="input is-primary" id="email" style="margin-bottom:20px" type="email" placeholder="Email">\
                  <br/>\
                  ',
                  showCancelButton: true,
                  confirmButtonText: "Book Appointment",
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                  preConfirm: () => {
                    

                    ////console.log("preconfirm");
                    const name = document.getElementById("name").value;
                    const phoneNumber =
                      document.getElementById("phoneNumber").value;
                    const dob = document.getElementById("dob").value;
                    const gender = document.getElementById("gender").value;
                    const opdunitId = this.state.opdUnitID;
                    const appointmentDate = this.state.data.appointmentDate;
                    const branch = this.state.data.branch;
                      const email = document.getElementById("email").value;
                    let time = this.state.data.selected;

                    const dateTime = appointmentDate + " " + time;
                    const json = {
                      fullName: name,
                      gender: gender,
                      phoneNumber: phoneNumber,
                      dob: dob,
                      opdunitId: opdunitId,
                      appointmentDateTime: dateTime,
                      branch: branch,
                      email: email,
                      
                    };
                    // console.log(json);
                    Swal.fire({
                      title: 'Booking Your Appointment Please Be Patient',
                      allowOutsideClick: false,
                      showConfirmButton: false,
                      willOpen: () => {
                        Swal.showLoading();
                      },
                    });
                   

                    
                    const axios = require("axios").default;
                    axios
                      .post(
                        "https://appointment.wmcethiopia.com/filter/makeAppointment.php",
                        json
                      )
                      .then(function (response) {
                        // handle success
                        Swal.fire(
                          "Appointment Booked!",
                          "You will get an sms Confirmation Shortly.",
                          "success"
                        );
                        ////console.log("Success");
                        ////console.log(response);
                      })
                      .catch(function (error_) {
                        // handle error
                        Swal.fire(
                          "Error",
                          "Something Went wrong Please Try again",
                          "error"
                        );
                        error = true;
                        ////console.log("error");
                        ////console.log(error_);
                      });
                  },
                }).then((result) => {
                  ////console.log(result);
                  if (result.isConfirmed) {
                  } else {
                    Swal.fire(
                      "Information",
                      "Operation cancelled by User",
                      "warning"
                    );
                  }
                });
              }}
            >
              Book Appointment
            </a>
          </div>
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <div className={"content"}>
          <Navbar
            onClicked={this.handleClick}
            pages={[
              { name: "Home", ref: "", link: "home" },
              // { name: "Book a Doctor", ref: "", link: "" },
            ]}
          />
        </div>
        <div className="tile is-parent" style={{ paddingTop: "70px" }}>
          <article
            className="tile is-child notification"
            style={{ backgroundColor: "white" }}
          >
            <div className="columns is-multiline">
              <div className="column">
                <div className="columns">
                  <div className="column">
                    <div style={{ width: "250px", height: "250px" }}>
                      <figure className="image is-4by3">
                        <img
                          src={this.state.image}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png";
                          }}
                          style={{ objectFit: "contain" }}
                          alt="Placeholder image"
                        />
                      </figure>
                    </div>
                    <h5 style={{ textAlign: "left" }}>
                      {this.state.doctorName}
                    </h5>
                    <h6
                      style={{
                        textAlign: "left",
                        fontSize: "15px",
                        color: "#C2C2C2",
                      }}
                    >
                      {this.state.speciality}
                    </h6>
                    {/* <h6
                      style={{
                        textAlign: "left",
                        fontSize: "15px",
                        color: "#C2C2C2",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faMapLocation} /> Bole Bulbula
                    </h6> */}
                    {/* <h6
                      style={{
                        textAlign: "left",
                        fontSize: "15px",
                        color: "#C2C2C2",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faMapLocation} /> Bole Rwanda
                    </h6> */}
                  </div>
                </div>
              </div>
              <div className="column ">
                <p className="title"></p>
                <p className="subtitle">Available Day and time</p>
                <div className="content">
                  <div className="control column">
                    {/* put appointment times */}
                    <div className="content">
                      <table className="table">
                        <thead>
                          <tr></tr>
                          <tr></tr>
                        </thead>
                        <tbody>
                          <AppointmentDay
                            day="Monday"
                            location={this.state.doctor.monday}
                            start1={this.state.doctor.availableStartTimeMonday}
                            end1={this.state.doctor.availableEndTimeMonday}
                            start2={this.state.doctor.availableStartTimeMonday1}
                            end2={this.state.doctor.availableEndTimeMonday1}
                          />
                          <AppointmentDay
                            day="Tuesday"
                            location={this.state.doctor.tuesday}
                            start1={this.state.doctor.availableStartTimeTuesday}
                            end1={this.state.doctor.availableEndTimeTuesday}
                            start2={
                              this.state.doctor.availableStartTimeTuesday1
                            }
                            end2={this.state.doctor.availableEndTimeTuesday1}
                          />
                          <AppointmentDay
                            day="Wednesday"
                            location={this.state.doctor.wednesday}
                            start1={
                              this.state.doctor.availableStartTimeWednesday
                            }
                            end1={this.state.doctor.availableEndTimeWednesday}
                            start2={
                              this.state.doctor.availableStartTimeWednesday1
                            }
                            end2={this.state.doctor.availableEndTimeWednesday1}
                          />
                          <AppointmentDay
                            day="Thursday"
                            location={this.state.doctor.thursday}
                            start1={
                              this.state.doctor.availableStartTimeThursday
                            }
                            end1={this.state.doctor.availableEndTimeThursday}
                            start2={
                              this.state.doctor.availableStartTimeThursday1
                            }
                            end2={this.state.doctor.availableEndTimeThursday1}
                          />
                          <AppointmentDay
                            day="Friday"
                            location={this.state.doctor.friday}
                            start1={this.state.doctor.availableStartTimeFriday}
                            end1={this.state.doctor.availableEndTimeFriday}
                            start2={this.state.doctor.availableStartTimeFriday1}
                            end2={this.state.doctor.availableEndTimeFriday1}
                          />
                          <AppointmentDay
                            day="Saturday"
                            location={this.state.doctor.saturday}
                            start1={
                              this.state.doctor.availableStartTimeSaturday
                            }
                            end1={this.state.doctor.availableEndTimeSaturday}
                            start2={
                              this.state.doctor.availableStartTimeSaturday1
                            }
                            end2={this.state.doctor.availableEndTimeSaturday1}
                          />
                          <AppointmentDay
                            day="Sunday"
                            location={this.state.doctor.sunday}
                            start1={this.state.doctor.availableStartTimeSunday}
                            end1={this.state.doctor.availableEndTimeSunday}
                            start2={this.state.doctor.availableStartTimeSunday1}
                            end2={this.state.doctor.availableEndTimeSunday1}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="content">
                  <div className="column ">
                  <p className="subtitle">Select Appointment Date</p>
                    <div className="content">

                    <Calandar
                      onChange={(date) => {
                        let newDate = new Date(date.data.datePicker._date.start);
                        const year = newDate.getFullYear();
                        const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
                        const day = newDate.getDate().toString().padStart(2, '0');
                        newDate = `${year}-${month}-${day}`;
                        let data = this.state.data;
                        data = { ...data, appointmentDate: newDate };
                        
                        this.setState({
                          data,
                        });

                        // //console.log(date.data.element.value);
                      }}
                      doctor={this.state.doctor}
                    />
                    <input
                      type="date"
                      id="appointmentDate"
                      name="appointmentDate"
                    />
                    </div>
                    {this.renderTimeBoxes()}
                    {this.renderAppointment()}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>

        <div className="columns  is-centered">
          <div className="field ">
            {/* <div className="column">
              {this.renderSelect("branch", "Branch", [
                "Washington Medical Center Bulbula branch",
                "Washington Medical Center Rwanda branch",
              ])}
            </div> */}
            {/* <div className="control column">
              {this.renderInput("appointmentDate", "Appointment Date", "date")}
            </div> */}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(BookAppointment);
