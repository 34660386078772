import React from "react";
import moment from "moment";
const AppointmentDay = ({ day, start1, end1, start2, end2, location }) => {
    const branch = location == 2 ? "at Bole Bulbula" : (location == 1 ? " at Bole Rwanda " : "")
   
  if (
    !(start1 == "00:00:00" && end1 == "00:00:00") ||
    !(start2 == "00:00:00" && end2 == "00:00:00")
  ) {
    return (
      <tr>
        <td>{day}</td>
        <td>
          <div>
            {start1 != end1 && (
              <>
                {moment(start1, "HH:mm:ss").format("hh:mm A")} -{" "}
                {moment(end1, "HH:mm:ss").format("hh:mm A")}
                <h6>
                {branch}
                </h6>
              </>
            )}
            {start2 != end2 && (
              <>
                {moment(start2, "HH:mm:ss").format("hh:mm A")} -{" "}
                {moment(end2, "HH:mm:ss").format("hh:mm A")}
                <h6>
                  {branch}
                </h6>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td>{day}</td>
        <td>
          <div>
            <h6 style={{ color: "red" }}>Not Available</h6>
          </div>
        </td>
      </tr>
    );
  }
};

export default AppointmentDay;
