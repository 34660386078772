import logo from "./logo.svg";
import "./App.css";
import Index from "./screens/index";
import BookAppointment from "./screens/bookAppointment";

import { Route, Routes } from "react-router-dom";
import NotFound from './screens/notFound';
import Profile from "./screens/profile";
function App() {
  
  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<Index/>} />
      <Route path="/home" element={<Index/>} />
      <Route path="/appointment/:_id" element={<BookAppointment/>} />
      {/* <Route path="/appointment" element={<BookAppointment/>} /> */}
      <Route path="/profile/:_id" element={<Profile/>} />
      <Route path="*" element={<NotFound/>} />
      </Routes>
      {/* <Index /> */}
      {/* <Profile /> */}
    </div>
  );
}

export default App;
