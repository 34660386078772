import React from "react";
import '../style/main.css';
const TimeBox = ({time,id,selected,onSelected}) => {
    const backgrounColor = "#42C0FB";
  return (
    <div className="column" onClick={() => {onSelected(id)}} style={{"cursor":"pointer"}} >
      <div className="card" id="timeBox" style={id==selected?{backgroundColor:"#42C0FB"}:{}}>
        <h6 style={{ fontSize: "22px", fontWeight: "bold", color: id==selected?"white":"#A28775",padding:"0.4em",userSelect:"none" }}>
          {time}
        </h6>
      </div>
    </div>
  );
};

export default TimeBox;
