import React, { Component } from "react";
import Navbar from "../common/navBar1";
import { withRouter } from "../utils/withRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faLocationDot,
  faCalendarAlt,
  faClock,
  faMapLocation,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../common/footer";

class Profile extends Component {
  state = {
    _id: "",
    doctorName: "",
    image: "",
    speciality: "",
  };
  componentDidMount() {
    const Swal = require("sweetalert2");
    const data_ = {
      0: {
        name: "Dr. Fassil Teffera",
        image:
          "https://wmcethiopia.com/sites/default/files/Dr.%20Fassil%20Teffera.jpg",
        speciality:
          "Internal Medicine (US Board Certified) and General Surgery",
      },
      1: {
        name: "Dr. Gasemalbari Mustafa",
        image: "https://wmcethiopia.com/sites/default/files/Dr.%20Gasim_0.png",
        speciality: "Clinical Hematology",
      },
      2: {
        name: "Dr. Yoseph Mamo",
        image: "https://wmcethiopia.com/sites/default/files/Dr%20Yosseph.png",
        speciality: "Internal Medicine",
      },
    };
    // handele non existant Id's
    const _id = this.props.params["_id"];
    this.setState({
      doctorName: data_[_id].name,
      image: data_[_id].image,
      speciality: data_[_id].speciality,
      id: _id,
    });
    //console.log("component did mount", data_[_id].name);
    //console.log(this.props.params);
  }
  render() {
    return (
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <div className={"content"}>
          <Navbar
            onClicked={this.handleClick}
            pages={[
              { name: "Home", ref: "", link: "home" },
              // { name: "Book a Doctor", ref: "", link: "" },
            ]}
          />
        </div>
        <div className="tile is-parent" style={{ paddingTop: "70px" }}>
          <article
            className="tile is-child notification"
            style={{ backgroundColor: "white" }}
          >
            <div className="columns">
              <div className="column" style={{ left: "0px" }}>
                <div className="columns">
                  <div className="column is-one-third">
                    <div style={{ width: "250px", height: "250px" }}>
                      <figure className="image is-4by3">
                        <img
                          src={this.state.image}
                          style={{ objectFit: "contain" }}
                          alt="Placeholder image"
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="column">
                    <h5 style={{ textAlign: "left" }}>
                      {this.state.doctorName}
                    </h5>
                    <h6
                      style={{
                        textAlign: "left",
                        fontSize: "15px",
                        color: "#C2C2C2",
                      }}
                    >
                      {this.state.speciality}
                    </h6>
                    <h6
                      style={{
                        textAlign: "left",
                        fontSize: "15px",
                        color: "#C2C2C2",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faMapLocation} /> Bole Bulbula
                    </h6>
                    <h6
                      style={{
                        textAlign: "left",
                        fontSize: "15px",
                        color: "#C2C2C2",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faMapLocation} /> Bole Rwanda
                    </h6>
                    <h6
                      style={{
                        textAlign: "left",
                        fontSize: "15px",
                        color: "#C2C2C2",
                      }}
                    >
                      <FontAwesomeIcon icon={faClock} />
                      &nbsp; Available Mon-Thu 8:00 AM - 5:00 PM
                    </h6>
                    <div className="column" style={{ left: "0px" }}>
                      <button
                        className="button is-success "
                        onClick={() =>
                          this.props.navigate(`/appointment/${this.state.id}`)
                        }
                      >
                        Book Appointment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <p className="title"></p>
                {/* <p className="subtitle">Aligned with the right tile</p> */}
                <div className="content"></div>
              </div>
            </div>
          </article>
        </div>
        <div className="content">
          <div className="tile is-parent" style={{ paddingTop: "70px" }}>
            <article
              className="tile is-child notification"
              style={{ backgroundColor: "white" }}
            >
              <b>
                <h1 style={{ textAlign: "left" }}>About Me</h1>
              </b>
              <div
                className="columns"
                style={{ marginTop: "20px", textAlign: "left" }}
              >
                <div className="column is-four-fifths">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Molestias dolorum distinctio aspernatur nesciunt ducimus
                    fuga possimus architecto velit consequuntur esse? Expedita
                    cumque deserunt illum esse perspiciatis? Aperiam cum quasi
                    explicabo? Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Molestias dolorum distinctio aspernatur
                    nesciunt ducimus fuga possimus architecto velit consequuntur
                    esse? Expedita cumque deserunt illum esse perspiciatis?
                    Aperiam cum quasi explicabo? Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Molestias dolorum distinctio
                    aspernatur nesciunt ducimus fuga possimus architecto velit
                    consequuntur esse? Expedita cumque deserunt illum esse
                    perspiciatis? Aperiam cum quasi explicabo?
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(Profile);
